import * as React from "react"
import Layout from "../layout/Layout"
import CTA from "../components/CTA"
import FabricInfo from "../components/FabricInfo"
import SEO from "../components/SEO"
import ReactMarkdown from "react-markdown"
import { slugReplace } from "../components/Reel"
import { graphql, navigate } from "gatsby"
import "./fabric.scss"
import clothBg from "../images/buttonBg.png"

const TitleWithImage = ({ img, title }) => {
  return (
    <div
      className="title-with-image"
      style={{ backgroundImage: `url(${img})` }}
    >
      <h1>{title}</h1>
      <div className="overlay"></div>
    </div>
  )
}

const Sidebar = ({ fabricsInMainState, state, currentTitle }) => {
  return (
    <div id="sidebar">
      <div className="fabrics-of-state">
        <h3>Fabrics Of India - {state}</h3>
      </div>
      {/* <Cards /> */}
      {fabricsInMainState.length > 0 &&
        fabricsInMainState.map((i) => {
          const frontmatter = i.frontmatter
          const title = frontmatter.title
          if (title === currentTitle) return null
          return (
            <div
              onClick={() => navigate(slugReplace(i.fields.slug))}
              onKeyDown={() => navigate(slugReplace(i.fields.slug))}
              role="button"
              tabIndex={0}
              key={title}
              className="card"
              style={{ backgroundImage: `url(${clothBg})` }}
            >
              <div className="image-container fabric-sample">
                <img
                  src={
                    frontmatter?.thumbnail
                      ? frontmatter.thumbnail
                      : `https://via.placeholder.com/600x200?text=${title
                          .split(" ")
                          .join("+")}`
                  }
                  alt={title}
                />
              </div>
              <div className="text-container">
                <h4>{title}</h4>
                <p>
                  ₹{frontmatter.min_price} - {frontmatter.max_price}
                </p>
              </div>
            </div>
          )
        })}
    </div>
  )
}
const FabricTemplate = (props) => {
  const { data } = props
  const fabric = data.markdownRemark.frontmatter
  const siteTitle = data.site.siteMetadata?.title || `Fabricsthan`
  const pageTitle = fabric.title
  const state = fabric.state?.[0] ?? ""

  const fabricsInMainState = data.fabricsInMainState?.edges.map((i) => i.node)
  const sidebarExists = fabricsInMainState && fabricsInMainState.length > 1
  return (
    <Layout bgAlt={siteTitle}>
      <SEO
        title={`${pageTitle} | ${siteTitle} | Fabrics Of India`}
        description={fabric.meta_description}
        image={fabric.thumbnail}
        pageContext={props.pageContext}
      />
      <div
        id="fabric-page"
        className={`container ${sidebarExists ? "" : "no-sidebar"}`}
      >
        <div id="fabric-content">
          <div>
            <TitleWithImage title={pageTitle} img={fabric.thumbnail} />
          </div>
          <div className="flex-container">
            <div>
              <FabricInfo
                data={fabric}
                extra={{
                  fabricBases: data.fabricBases?.edges.map((i) => i.node),
                  states: data.states?.edges.map((i) => i.node),
                }}
              />
            </div>

            {fabric.history && (
              <section>
                <h2>History</h2>
                <div className="history fabric-section">
                  <div>
                    <ReactMarkdown>{fabric.history}</ReactMarkdown>
                  </div>
                  <div className="image-container">
                    {fabric.history_image && (
                      <img
                        src={fabric.history_image}
                        alt={`${fabric.title} History`}
                      />
                    )}
                  </div>
                </div>
              </section>
            )}
            {fabric.description && (
              <section>
                <h2>Description</h2>
                <div className="description fabric-section">
                  <div>
                    <ReactMarkdown>{fabric.description}</ReactMarkdown>
                  </div>
                  <div className="image-container">
                    {fabric.description_image && (
                      <img
                        src={fabric.description_image}
                        alt={`${fabric.title} Description`}
                      />
                    )}
                  </div>
                </div>
              </section>
            )}
            {fabric.source && (
              <section>
                <h2>Where to Source</h2>
                <p>{fabric.source}</p>
              </section>
            )}
            {/* <div className="photos-container">
              <h2>Photos</h2>
              <div className="images-container">
                <div className="image-container">
                  <img
                    src={`https://via.placeholder.com/300x400?text=Photo+1`}
                    alt=" 1"
                  />
                </div>
                <div className="image-container">
                  <img
                    src={`https://via.placeholder.com/300x400?text=Photo+2`}
                    alt=" 2"
                  />
                </div>
                <div className="image-container">
                  <img
                    src={`https://via.placeholder.com/300x400?text=Photo+3`}
                    alt=" 3"
                  />
                </div>
              </div>
            </div> */}
          </div>

          <div id="contact">
            <h2>Contact Us For Inquiry</h2>
            <p>
              Reach out to us at{" "}
              <a href="mailto:fabricsthan@gmail.com">fabricsthan@gmail.com</a>{" "}
              and we’ll help you find your fabric
            </p>
            <CTA
              heading={`Want to see other fabrics from ${state}?`}
              buttonLabel={`View All In ${state}`}
              buttonLink={`/state/${state.toLowerCase()}`}
            />
          </div>
        </div>
        {sidebarExists && (
          <Sidebar
            fabricsInMainState={fabricsInMainState}
            currentTitle={pageTitle}
            state={fabric.state[0]}
          />
        )}
      </div>
    </Layout>
  )
}

export default FabricTemplate
export const pageQuery = graphql`
  query FabricPost(
    $id: String!
    $mainState: String
    $fabricBase: [String]
    $state: [String]
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    # Fetch all states that have this as fabric (bihar, telangaga for ikkat)
    states: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/state/.*.md/" }
        frontmatter: { title: { in: $state } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    # Fetch other fabrics from this state to display in sidebar
    fabricsInMainState: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/fabric/.*.md/" }
        frontmatter: { state: { in: [$mainState] } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            thumbnail
            min_price
            max_price
          }
        }
      }
    }
    # Fetch all fabric bases of this type of fabric (cotton, silk for ikkat)
    fabricBases: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/fabric_base/.*.md/" }
        frontmatter: { title: { in: $fabricBase } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        state
        care
        care_addtional
        description
        description_image
        fabric_base
        history
        history_image
        max_price
        min_price
        popularity
        source
        texture
        thumbnail
        title
        usecase
        weather
        weather_additional
        meta_description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
