import * as React from "react"
import "./fabricInfo.scss"
import { Link } from "gatsby"
import { slugReplace } from "./Reel"

const ListItem = ({ label, value, extra }) => {
  return (
    <li>
      <span className="label">{label}</span>
      <span className="value">
        {value} {extra && <span className="extra">{extra}</span>}
      </span>
    </li>
  )
}

const FabricInfo = ({ data, extra }) => {
  if (!data) return null
  return (
    <ul id="fabric-info">
      {/* <ListItem label="Base Material" /> */}
      {extra && extra.fabricBases?.length && (
        <ListItem
          label="Bsae Material"
          value={extra.fabricBases.map((i, idx) => (
            <Link key={idx} to={slugReplace(i.fields.slug)} className="tag">
              {i.frontmatter.title}
            </Link>
          ))}
        />
      )}
      {extra && extra.states?.length && (
        <ListItem
          label="States"
          value={extra.states.map((i, idx) => (
            <Link key={idx} to={slugReplace(i.fields.slug)} className="tag">
              {i.frontmatter.title}
            </Link>
          ))}
        />
      )}
      {data.min_price !== undefined &&
        data.min_price !== null &&
        data.max_price !== undefined && (
          <ListItem
            label="Price Range"
            value={`₹${data.min_price} - ${data.max_price}`}
          />
        )}
      {data.weather && (
        <ListItem
          label="Weather"
          value={data.weather}
          extra={data.weather_additional}
        />
      )}
      {data.popularity && (
        <ListItem label="Popularity" value={data.popularity} />
      )}
      {data.production && (
        <ListItem label="Production" value={data.production} />
      )}
      {data.care && <ListItem label="Care" value={data.care} />}
      {data.texture && <ListItem label="Texture" value={data.texture} />}
      {data.usecase && <ListItem label="Usecase" value={data.usecase} />}
    </ul>
  )
}

export default FabricInfo
